import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import GridTitleContainer from "../../commons/GridTitleContainer";
import HttpContext from "../../../services/HttpContext";
import UserContext from "../../../services/UserContext";
import Constants, { roleOptions } from "../../../Constants";
import BusinessLocationForm from "./BusinessLocationForm";
import BusinessDetailsForm from "./BusinessDetailsForm";
import AuthorizedUserForm from "./AuthorizedUserForm";
import SubscriptionPlanForm from "./SubscriptionPlanForm";
import ConfirmationDialog from "../../commons/ConfirmationDialog";

const REGEX = /^[^<>&"'/\\]{0,50}$/;

const initialState = {
  businessName: "",
  teamName: "",
  businessLocation: "",
  countryCodeBusinessPhoneNumber: "",
  businessPhoneNumber: "",
  businessEmailAddress: "",
  primary: {
    firstname: "",
    lastname: "",
    role: "",
    officialEmail: "",
    countryCodeRepresentativePhoneNumber: "",
    representativePhoneNumber: "",
    comment: "",
  },
  secondary: {
    firstname: "",
    lastname: "",
    role: "",
    officialEmail: "",
    countryCodeRepresentativePhoneNumber: "",
    representativePhoneNumber: "",
    comment: "",
  },
  pricingPlan: "",
  billingStartDate: "",
  showConfirmation: false,
  showCancelConfirmation: false,
  plans: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "updateField":
      if (
        action.event.name === "countryCodeBusinessPhoneNumber" ||
        action.event.name === "countryCodeRepresentativePhoneNumber" ||
        REGEX.test(action.event.value)
      )
        return { ...state, [action.event.name]: action.event.value };
      return state;
    case "updateNestedField":
      return {
        ...state,
        [action.parent]: {
          ...state[action.parent],
          [action.event.name]: action.event.value,
        },
      };
    case "toggleConfirmation":
      return { ...state, showConfirmation: !state.showConfirmation };
    case "toggleCancelConfirmation":
      return { ...state, showCancelConfirmation: !state.showCancelConfirmation };
    case "setPlans":
      return { ...state, plans: action.payload };
    default:
      return state;
  }
};

function NewClientOnboardingForm() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { GetRequest, PostRequest } = useContext(HttpContext);
  const {
    user: { email },
  } = useContext(UserContext);
  const firstRender = useRef(true);

  const [state, dispatch] = useReducer(reducer, initialState);

  const isFormValid = () => {
    return (
      state.businessName &&
      state.teamName &&
      state.businessLocation &&
      state.businessEmailAddress &&
      state.countryCodeBusinessPhoneNumber &&
      state.businessPhoneNumber &&
      state.primary.firstname &&
      state.primary.lastname &&
      state.primary.role &&
      state.primary.officialEmail &&
      state.primary.countryCodeRepresentativePhoneNumber &&
      state.primary.representativePhoneNumber &&
      state.primary.comment &&
      state.secondary.firstname &&
      state.secondary.lastname &&
      state.secondary.role &&
      state.secondary.officialEmail &&
      state.secondary.countryCodeRepresentativePhoneNumber &&
      state.secondary.representativePhoneNumber &&
      state.secondary.comment &&
      state.pricingPlan &&
      state.billingStartDate
    );
  };

  const isFormNotEmpty = () => {
    return (
      state.businessName ||
      state.teamName ||
      state.businessLocation ||
      state.countryCodeBusinessPhoneNumber ||
      state.businessPhoneNumber ||
      state.businessEmailAddress ||
      state.primary.firstname ||
      state.primary.lastname ||
      state.primary.role ||
      state.primary.officialEmail ||
      state.primary.countryCodeRepresentativePhoneNumber ||
      state.primary.representativePhoneNumber ||
      state.primary.comment ||
      state.secondary.firstname ||
      state.secondary.lastname ||
      state.secondary.role ||
      state.secondary.officialEmail ||
      state.secondary.countryCodeRepresentativePhoneNumber ||
      state.secondary.representativePhoneNumber ||
      state.secondary.comment ||
      state.pricingPlan ||
      state.billingStartDate
    );
  };

  const navigate = useNavigate();

  const handleFormSubmit = () => {
    dispatch({ type: "toggleConfirmation" });
  };

  const handleCancel = () => {
    if (isFormNotEmpty()) {
      dispatch({ type: "toggleCancelConfirmation" });
    } else {
      navigate(Constants.CLIENT_ONBOARDING_URL);
    }
  };

  const handleCancelConfirmationConfirm = () => {
    navigate(Constants.CLIENT_ONBOARDING_URL);
  };

  const handleConfirmationSubmit = async () => {
    const formData = {
      businessEmail: state.businessEmailAddress,
      customerData: {
        businessDetails: {
          businessLocation: state.businessLocation,
          businessPhone: `${state.countryCodeBusinessPhoneNumber}${state.businessPhoneNumber}`,
          businessEmail: state.businessEmailAddress,
        },
        businessRepresentativeDetail: {
          comment: state.primary.comment,
          companyRepresentative: `${state.primary.firstname} ${state.primary.lastname}`,
          officialEmail: state.primary.officialEmail,
          phone: `${state.primary.countryCodeRepresentativePhoneNumber}${state.primary.representativePhoneNumber}`,
          role: state.primary.role,
        },
        secondaryBusinessRepresentativeDetail: {
          comment: state.secondary.comment,
          companyRepresentative: `${state.secondary.firstname} ${state.secondary.lastname}`,
          officialEmail: state.secondary.officialEmail,
          phone: `${state.secondary.countryCodeRepresentativePhoneNumber}${state.secondary.representativePhoneNumber}`,
          role: state.secondary.role,
        },
        subscriptionPlan: {
          billingStartDate: state.billingStartDate,
          pricingPlan: state.pricingPlan,
        },
      },
      initiatedBy: email,
      legalBusinessName: state.businessName,
      teamName: state.teamName,
    };

    try {
      await PostRequest(Constants.REACT_APP_API_CREATE_NEW_CLIENT_REQUEST, formData);
      navigate(Constants.CLIENT_ONBOARDING_URL, {
        state: {
          businessEmailAddress: state.businessEmailAddress,
          snackbarVariant: "success",
          snackbarMsg: "Client onboarding request created successfully",
          snackbarOpen: true,
        },
      });
    } catch (e) {
      navigate(Constants.CLIENT_ONBOARDING_URL, {
        state: {
          snackbarVariant: "failure",
          snackbarMsg: e.message,
          snackbarOpen: true,
        },
      });
      console.error(e);
    }
    dispatch({ type: "toggleConfirmation" });
  };

  const [validationErrors, setValidationErrors] = useState({
    businessEmailAddress: "",
    businessPhoneNumber: "",
    primaryOfficialEmail: "",
    secondaryOfficialEmail: "",
    repEmailDupe: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
    repPhoneDupe: "",
    billingStartDate: "",
    businessEmailExists: "",
  });

  const validateEmail = (value) => {
    if (!value) {
      return "Business email address is required";
    }
    if (!REGEX.test(value)) {
      return "Field cannot contain characters < > \\ /";
    }
    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
      return "Invalid email address";
    }
    return "";
  };

  const validatePhoneNumber = (value) => {
    if (!value) {
      return "Phone number is required";
    }

    if (!REGEX.test(value)) {
      return "Field cannot contain characters < > \\ /";
    }

    let phoneNumberRegex;
    let errorMessage;
    
    switch (state.businessLocation) {
      case "IN":
        phoneNumberRegex = /^\d{10}$/;
        errorMessage = "Invalid phone number for India (should be 10 digits)";
        break;
      case "DE":
        phoneNumberRegex = /^\d{5,13}$/;
        errorMessage = "Invalid phone number for Germany (should be between 5 and 13 digits)";
        break;
      default:
        return "";
    } // later add more validations

    if (!phoneNumberRegex.test(value)) {
      return errorMessage;
    }

    return "";
  };

  const validateBillingStartDate = (dateString) => {
    const selectedDate = new Date(dateString);
    const currentDate = new Date();
    const maxFutureDate = new Date();
    maxFutureDate.setFullYear(currentDate.getFullYear() + 1);

    // Set time to midnight for both selectedDate and currentDate
    selectedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    if (Number.isNaN(selectedDate.getTime())) return "Enter a valid date";
    if (selectedDate < currentDate) return "Billing date can't be in the past";
    if (selectedDate >= maxFutureDate) return "Billing date can't be more than 1 year from now";

    return "";
  };

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "businessEmailAddress":
        return validateEmail(value);
      case "businessPhoneNumber":
        return validatePhoneNumber(value);
      case "primaryOfficialEmail":
        return validateEmail(value);
      case "secondaryOfficialEmail":
        return validateEmail(value);
      case "primaryPhoneNumber":
        return validatePhoneNumber(value);
      case "secondaryPhoneNumber":
        return validatePhoneNumber(value);
      case "billingStartDate":
        return validateBillingStartDate(value);
      default:
        return "";
    }
  };

  const validateRepresentativeEmails = (repType, value) => {
    const toCheck = repType === "primary" ? state.secondary.officialEmail : state.primary.officialEmail;
    if (REGEX.test(value)) {
      return "Field cannot contain characters < > \\ /";
    }
    if (value === toCheck) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        repEmailDupe: "Primary and secondary email can't be the same",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        repEmailDupe: "",
      }));
    }
  };

  const validateRepresentativePhones = (repType, value) => {
    const toCheck =
      repType === "primary" ? state.secondary.representativePhoneNumber : state.primary.representativePhoneNumber;
    if (value === toCheck) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        repPhoneDupe: "Primary and secondary phone numbers can't be the same",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        repPhoneDupe: "",
      }));
    }
  };

  const handleInputChange = (fieldName, value) => {
    const error = validateField(fieldName, value);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  useEffect(() => {
    // Skip validation on the first render
    if (!firstRender.current) {
      if (state.businessPhoneNumber) {
        handleInputChange("businessPhoneNumber", state.businessPhoneNumber);
      }
      if (state.primary.representativePhoneNumber) {
        handleInputChange("primaryPhoneNumber", state.primary.representativePhoneNumber);
      }
      if (state.secondary.representativePhoneNumber) {
        handleInputChange("secondaryPhoneNumber", state.secondary.representativePhoneNumber);
      }
    } else {
      firstRender.current = false;
    }
  }, [state.businessLocation]);

  const fetchPricingPlans = async () => {
    try {
      const response = await GetRequest(
        `${Constants.REACT_APP_API_FETCH_PRICING_PLAN_LIST}?countrycode=${state.businessLocation}`,
      );
      if (response && response?.plans) {
        dispatch({ type: "setPlans", payload: response?.plans });
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  const checkUserExist = async (fieldName) => {
    try {
      const response = await GetRequest(
        `${Constants.REACT_APP_API_CHECK_TENANT_EXISTS}?email=${state.businessEmailAddress}`,
      );

      if (response) {
        const error = response?.businessEmailExists === true ? "Email address already exists" : "";
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error,
        }));
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  useEffect(() => {
    fetchPricingPlans();
  }, [state.businessLocation]);

  return (
    <>
      <GridTitleContainer testid="clientOnboard-page-heading">Onboard a new client</GridTitleContainer>
      <Box sx={{ ml: isSmallScreen ? 2 : 4, mt: 4, mb: 4, mr: isSmallScreen ? 2 : "492px" }}>
        <BusinessLocationForm state={state} dispatch={dispatch} />
        <BusinessDetailsForm
          state={state}
          dispatch={dispatch}
          handleInputChange={handleInputChange}
          validationErrors={validationErrors}
          checkUserExist={checkUserExist}
        />
        <AuthorizedUserForm
          userKey="primary"
          state={state}
          dispatch={dispatch}
          handleInputChange={handleInputChange}
          validationErrors={validationErrors}
          validateRepresentativeEmails={validateRepresentativeEmails}
          validateRepresentativePhones={validateRepresentativePhones}
          roleOptions={roleOptions}
        />
        <AuthorizedUserForm
          userKey="secondary"
          state={state}
          dispatch={dispatch}
          handleInputChange={handleInputChange}
          validationErrors={validationErrors}
          validateRepresentativeEmails={validateRepresentativeEmails}
          validateRepresentativePhones={validateRepresentativePhones}
          roleOptions={roleOptions}
        />
        <SubscriptionPlanForm
          state={state}
          dispatch={dispatch}
          plans={state.plans}
          validationErrors={validationErrors}
        />
        <Divider sx={{ mt: 4, mb: 4, borderStyle: "dashed" }} />
        <Grid container justifyContent="flex-start" flexWrap="nowrap">
          <Grid item xs={11} />
          <Grid item>
            <Button
              onClick={handleCancel}
              variant="outlined"
              sx={{
                borderRadius: "100px",
                color: "#212121",
                border: "1px solid rgba(0, 0, 0, 0.25)",
                textTransform: "none",
              }}
              data-testid="clientOnboard-cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              sx={{
                ml: "10px",
                backgroundColor: "#EA3E27",
                color: "white",
                textTransform: "none",
                borderRadius: "100px",
              }}
              onClick={handleFormSubmit}
              disabled={!isFormValid() || Object.values(validationErrors).some((error) => error !== "")}
              data-testid="clientOnboard-submit-button"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>

      <ConfirmationDialog
        open={state.showConfirmation}
        onClose={() => dispatch({ type: "toggleConfirmation" })}
        onConfirm={handleConfirmationSubmit}
        title="Confirmation"
        content="Please check the information before submitting this request."
      />

      <ConfirmationDialog
        open={state.showCancelConfirmation}
        onClose={() => dispatch({ type: "toggleCancelConfirmation" })}
        onConfirm={handleCancelConfirmationConfirm}
        title="Confirmation"
        content="You'll lose the data you've entered. Are you sure you want to cancel?"
      />
    </>
  );
}

export default NewClientOnboardingForm;
